.navigation {
    width: 100%;
    position: sticky;
    top: 0;
    padding: var(--main-padding);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: inset -1px 7px 5px -3px rgb(28, 28, 94);
    font-size: 4vmin;
    background-color: var(--primary-color);
    z-index: 12;
}
.navigation a {
    margin-right: 3vmin;
    color: var(--text-color);
    text-decoration: none;
}
.active {
    color: var(--secondary-color) !important;
    text-decoration: underline !important;
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
    .navigation {
         width: 100%;
        padding: var(--mobile-padding);
        display: flex;
        justify-content: space-around;
    }
    .navigation a {
        margin-right: 0;
    }
}
