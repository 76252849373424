.header {
    background-color: var(--primary-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    color: var(--secondary-color);
    padding: var(--main-padding);
    z-index: 10;
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
    .header {
        padding: var(--mobile-padding);
    }
}
.infoLine {
    display: flex;
    height: 3vh;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
}
.leftIcons {
    display: flex;
    align-items: center;
}
.rightIcons {
    display: flex;
    align-items: center;
    justify-content: center;
}
.rightIcons p {
    margin: 10px;
}
.iconCircle {
    border-radius: 50%;
    background: var(--secondary-color);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
}
i {
    color: var(--primary-color);
    font-size: large;
}
.navigation {
    height: 9vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    box-shadow: 0px 0 10px black;
}
.navigation a {
    margin-right: 30px;
    color: var(--secondary-color);
    text-decoration: none;
}
.buttons {
    display: flex;
    align-items: center;
}
.button {
    color: var(--text-color);
    font-size: 2vmin;
    border: 1px solid var(--secondary-color);
    border-radius: 30px;
    background: var(--primary-color);
    width: 5vmin;
    height: 3vmin;
    margin: 0 5px;
    padding: 0;
    display: inline-block;
    text-align: center;
}
.buttonMain:hover {
    background: var(--secondary-color);
}
.span {
    color: var(--secondary-color);
    font-size: 1.5rem;
    margin-right: 5px;
}

.social {
    margin-right: 5px;
}
