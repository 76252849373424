@import './variables.css';

body {
  margin: 0;
  font-family: PerspectiveC, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: PerspectiveC;
  src: url('/public/font/PerspectiveC.otf');
}

