
.image {
  width: 100%;

  object-fit: cover;
  border-radius: 10px;
  color: rgb(255, 255, 255);

  height: 58rem;

  display: flex;
  justify-content: center;
  
  grid-auto-flow: dense;
  /* overflow: hidden; */

  transition: all 0.5s ease;
  opacity: 0;

}
.active {
  opacity: 1;
}
.image:hover {
  cursor: pointer;
  width: 100%;
  /* height: 38rem; */
}

.leftArrow,
.rightArrow {
  cursor: pointer;
  position: absolute;
  top: 90%;
  width: auto;
  margin: -450px;
  color: rgb(124, 124, 124);

  font-weight: bold;
  font-size: 60px;
  transition: 0.6s ease;
  border-radius: 25% 25%;
  user-select: none;
}
.rightArrow {
  right: 0;
  border-radius: 25% 25%;
}

.leftArrow:hover,
.rightArrow:hover {
  background-color: rgba(107, 107, 107, 0.5);
}

#fade {
  transition: all 2s ease-out;
  transition-delay: 2s;
}
