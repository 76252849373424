.intro {
  display: flex;
  align-items: center;
  height: 80vh;
  width: 100%;
  position: relative;
}
.videoWrapper .overlay {
  background: rgba(2, 1, 33, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.videoWrapper {
  height: 100vh;
}
.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
.imageWrapper {
  z-index: 2;
  margin-right: 15vmin;
  flex: 1 0 50%;
}
.imageWrapper img {
  padding-top: 2vmin;
  z-index: 1;
  height: 100%;
  width: auto;
}
.headerWrapper {
  display:flex;
  flex-direction:column;
  justify-content:center;
  z-index: 3;
  padding-left: 15vmin;
  width:50%;
}
@media only screen and (max-width: 1060px) {
  .imageWrapper{
    display:flex;
    justify-content: center;
    width:60%;
    flex-wrap: wrap;
    margin-right: 5vmin;
  }
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .intro {
    height: 50vh;
  }
  .imageWrapper{
    display:flex;
    justify-content: center;
    width:50%;
    height:80%;
    flex-wrap: wrap;
  }
}
