:root {
  --font: PerspectiveC, sans-serif;
  --primary-color: rgb(2, 1, 34);
  --secondary-color: rgb(200, 172, 101);
  --highlight-secondary-color: rgb(238, 209, 137);
  --gallery-color: rgb(46, 4, 80);
  --highlight-gallery-color: rgb(139, 40, 182);
  --contact-color: rgb(73, 0, 104);
  --text-color: #efdccc;
  --header-font-size: 10vmin;
  --main-padding: 12px 15vmin;
  --mobile-padding: 12px 5vmin;
  --title-font-size: 8vmin;
  --text-font-size: 4vmin;
}
