.reserve {
  position: relative;
  background-image: url(/public/images/fokusnik.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reserve::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.63);
}
.reserve::after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-size: 100%;
}
.textWrapper {
  position: relative;
  font-family: PerspectiveC;
  font-size: 7vmin;
  color: var(--text-color);
  text-align: center;
}

body {
  background-color: var(--primary-color);
}
.container {
  background-color: var(--primary-color);
  /* width: 100%;
  height: 100%; */

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* grid-auto-rows: 200px; */
  align-items: stretch;
  padding: 100px 200px 100px 200px;
  gap: 10px;
  grid-auto-flow: dense;
}

.img {
  max-width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.5s;
  border-radius: 10px;
}
.img:hover {
  cursor: pointer;
  opacity: 0.5;
}

.tall {
  grid-row: span 2;

  max-width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.5s;
  border-radius: 10px;
  border: 2px solid var(--gallery-color);
}
.wide {
  grid-column: span 2;
  
  max-width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.5s;
  border-radius: 10px;
  border: 2px solid var(--gallery-color);
}
.tall:hover {
  cursor: pointer;
  opacity: 0.5;
}
.wide:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .container {
    background-color: var(--primary-color);
    align-items: stretch;
    width: 100%;
    padding: var(--mobile-padding);
    gap: 5px;
    grid-auto-flow: dense;
  }
  .img {
    max-width: 100%;
    width: 100%;
  }
}
