.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--main-padding);
  font-size: 3vmin;
  color: var(--secondary-color);
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .footer {
    padding: var(--mobile-padding);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .list,
  .contacts,
  .follow {
    display: flex;
    flex: 1 1 100%;
  }
}

.title {
  font-size: 5vmin;
  text-align: start;
}
.items {
  list-style: none;
  text-align: start;
  padding-left: 0;
  color: var(--text-color);
}
.arrow::before {
  content: "\27A2";
  padding-right: 10px;
  color: var(--secondary-color);
  font-weight: bold;
  width: 30px;
  height: 30px;
}
.list,
.contacts,
.follow {
  display: flex;
  flex: 1 1 30%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.text {
  color: var(--text-color);
  font-size: 3vmin;
  text-align: start;
}
.phone::before {
  content: "\2706";
  padding-right: 10px;
  color: var(--secondary-color);
  font-size: 5vmin;
  font-weight: bold;
}
.email::before {
  content: "\2709";
  padding-right: 10px;
  color: var(--secondary-color);
  font-size: 5vmin;
  font-weight: bold;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.iconCircle {
  border-radius: 50%;
  background: var(--secondary-color);
  width: 4vmin;
  height: 4vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.iconCircle i {
  color: var(--primary-color);
  display: flex;
  font-size: 2.7vmin;
}
.gallery {
  display: grid;
  grid-template-columns: repeat(3, 55px);
  grid-template-rows: repeat(4, 55px);
  grid-gap: 3px;
  justify-items: start;
}
.title {
  justify-self: start;
  z-index: 3;
}
.gallery img {
  height: 55px;
  overflow: hidden;
}
.span {
  color: var(--secondary-color);
  font-size: 1.5rem;
  margin-right: 5px;
}
