.icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40vh;
  padding: var(--main-padding);
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .icons {
    padding: var(--mobile-padding);
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}
.icons .text {
  font-size: 3vmin;
  color: var(--text-color);
}
.icons span img {
  filter: invert(74%) sepia(49%) saturate(347%) hue-rotate(5deg) brightness(85%) contrast(92%);
  width: 100px;
}
