.reserve {
  position: relative;
  background-image: url(/public/images/cover.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: var(--primary-color);
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--main-padding);
}
.reserve::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.63);
}
.reserve::after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-size: 100%;
  background: url(/public/images/layered-waves-haikei7.svg) bottom no-repeat,
    url(/public/images/layered-waves-haikei6.svg) top no-repeat;
  content: "";
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  .reserve {
    padding: var(--mobile-padding);
  }
}
.textWrapper {
  position: relative;
  text-align: center;
}
.items {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding-left: 0;
  color: var(--text-color);
  font-size: var(--text-font-size);
}
.check {
  padding-right: 20px;
}
.check::before {
  content: "\2714";
  padding-right: 3px;
  color: var(--secondary-color);
  width: 30px;
  height: 30px;
}
