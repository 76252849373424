.reserve {
  position: relative;
  background-image: url(/public/images/fokusnik.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reserve::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.63);
}
.reserve::after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-size: 100%;
}
.textWrapper {
  position: relative;
  font-family: PerspectiveC;
  font-size: 7vmin;
  color: var(--text-color);
  text-align: center;
}

.mainContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: var(--main-padding);
}
.call {
  flex: 1 0 40%;
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
  border: 1px solid var(--contact-color);
  border-radius: 5px;
  margin: 10px;
}
.inquiry_form {
  flex: 1 0 40%;
}
.containerIn {
  width:100%;
  display: flex;
  flex-direction: column;
}

.textCall {
  color: rgb(121, 120, 120);
  font-size: 20px;
}
.textContact {
  color: var(--secondary-color);
  font-size: 3vmin;
  font-family: PerspectiveC;
}

.buttonCall {
  color: white;
  background-color:rgb(32, 0, 61);
  border: 1px solid var(--secondary-color);
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 25%);
  font-family: PerspectiveC;
  font-size: 1.2rem;
  padding: 15px;
  margin-top: 2rem;
}

.buttonCall:hover {
  background-color: var(--contact-color);
  transition: 0.5s;
}
.buttonCall:active {
  position: relative;
  top: 1px;
}

.buttonSend {
  color: white;
  background-color:var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 25%);
  font-family: PerspectiveC;
  font-size: 1.4rem;
  padding: 15px;
  width: 15rem;
}
.buttonSend:hover {
  background-color: var(--contact-color);
  transition: 0.5s;
}
.buttonSend:active {
  position: relative;
  top: 1px;
}

.title {
  color: var(--secondary-color);
  padding: 20px;
  font-family: PerspectiveC;
}
textarea {
  width: 100%;
  outline: none;
  border: 1px solid var(--contact-color);
  border-radius: 5px;
  background-color: var(--primary-color);
  padding: 10px;
  margin: 10px 0px 10px 0px;
  color: var(--secondary-color);
  font-family: PerspectiveC;
  font-size: 20px;
  padding-left: 50px;
}

textarea:focus {
  box-shadow: 0 0 10px var(--highlight-gallery-color);
}

input {
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid var(--contact-color);
  border-color: var(--contact-color);
  background-color: var(--primary-color);
  padding: 10px;
  margin: 10px 0px 10px 0px;
  color: var(--secondary-color);
  font-family: PerspectiveC;
  font-size: 20px;
  padding-left: 50px;
}

input:focus {
  box-shadow: 0 0 10px var(--highlight-gallery-color);
}

.inputWrapper {
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--mobile-padding);
  }
  .inquiry_form {
    flex: 1 1 80%;
  }
  .call {
    flex: 1 1 80%;
    max-width: 100%;
  }
  .buttonCall {
   width:60vmin;
   font-size: 18px;
   padding: 1.5vmin;
  }
}
